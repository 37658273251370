.linkButton{
    color: #FFF;
    border: 3px solid #FFF;
    padding: 30px 70px;
    border-radius: 80px;
    font-weight: 400;
    font-size: clamp(16px, 2vw, 24px);
    text-decoration: none;

    transition: .3s all ease-in-out;

    font-family: 'Inter', sans-serif;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    svg {
        stroke-width: 40px;
    }

    &:hover{
        color: #36FFC3;
        border-color: #36FFC3;

        transition: .3s all ease-in-out;
    }
}

.sm{
    padding: 14px 30px;
    font-size: clamp(16px, 2vw, 18px);
}

@media screen and (max-width: 1200px) {
    .linkButton{
        padding: 14px 30px;
        font-size: clamp(16px, 2vw, 18px);
    }
}