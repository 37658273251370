.subscribePage{
    padding: 8rem 7rem;
    max-width: 900px;
    
    &-links{
        margin-bottom: 80px;
        max-height: 50px;
    }

    &-content{
        color: #FFF;
        h2{
            font-size: clamp(18px, 4vw, 60px);
            line-height: 1.25;
            font-weight: 700;
            font-family: 'Inter', sans-serif;
            margin-bottom: 20px;
        }

        p{
            font-size: clamp(16px, 2vw, 24px);
            line-height: 1.75;
            font-weight: 400;
            font-family: 'Inter', sans-serif;
            margin-bottom: 20px;
        }

        div{
            
            form{
                display :flex;
                justify-content: space-between;
                input[type="email"]{
                    width: 65%;
                    background: transparent;
                    border: 2px solid rgba(128, 128, 128, .2);
                    border-radius: 50px;
                    padding: 20px 20px; 
                    font-size: clamp(16px, 2vw, 24px);
                    color: white;
                    outline: none;
    
                    &:focus{
                        border-color: #36FFC3;
                    }
                }
    
                .subButton{
                    color: #FFF;
                    border: 3px solid #FFF;
                    border-radius: 80px;
                    background: none;
    
                    cursor: pointer;
    
                    font-weight: 400;
                    text-decoration: none;
                    padding: 14px 30px;
                    font-size: clamp(16px, 2vw, 18px);
                    font-family: 'Inter', sans-serif;
                
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
    
                    transition: .3s all ease-in-out;
                
                    svg {
                        stroke-width: 40px;
                    }
                
                    &:hover{
                        color: #36FFC3;
                        border-color: #36FFC3;
                
                        transition: .3s all ease-in-out;
                    }
                }
            }

        }
    }
}
@media screen and (max-width: 1200px) {
    .subscribePage{
        padding: 8rem 3rem;
        &-content{
            div{
                form{
                    flex-direction: column;
                    gap: 15px;
                    align-items: center;
                    input[type="text"]{
                        max-width: 100%;
                        padding: 10px 30px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .subscribePage{
        padding: 3rem 1.8rem;
    }
}