.successPage{
    padding: 8rem 7rem;
    max-width: 900px;

    &-content{
        color: #FFF;
        h2{
            font-size: clamp(18px, 4vw, 60px);
            line-height: 1.25;
            font-weight: 700;
            font-family: 'Inter', sans-serif;
            margin-bottom: 20px;
        }
        p{
            font-size: clamp(16px, 2vw, 24px);
            line-height: 1.75;
            font-weight: 300;

            font-family: 'Inter', sans-serif;
            margin-bottom: 30px;
        }
    }
    &-links{
        display: flex;
        flex-wrap: wrap;
        gap: 35px;
    }
}

@media screen and (max-width: 768px) {
    .successPage{
        padding: 3rem 1.8rem;
    }
}